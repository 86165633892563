import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GetUserInfo, setCookie } from "./Utilities";

function RenderApp(email, tenantId)
{
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode >
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));


//Check if user is coming from AAD B2C
if(window.location.hash.includes("#id_token="))
{
  
  let handleLoginUrl = "https://func-app-service-worksheetwiz.azurewebsites.net/api/HandleLogin?code=VzuNCh9L7f5AIvfXlSASXtochUVU927aBtbay_Bezrj2AzFuNMAb6Q==";
  
  let jwtToken = window.location.hash.split("#id_token=")[1];

  fetch(handleLoginUrl + "&token=" + jwtToken).then(response => response.json()).then(json => {
    setCookie("email", json['email'], 365);
    setCookie("tenantId", json['tenantId'], 365);
    window.location.href = window.location.origin;
  });
}
//Check if user is "signed in"
else if(GetUserInfo().email.length > 0 && GetUserInfo().email.length > 0)
{
  RenderApp();
}
//Redirect user to sign-in-sign-up flow
else
{
  let azureB2C_URL = "https://worksheetwiznet.b2clogin.com/worksheetwiznet.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_sign-up-and-sign-in-flow&client_id=29a8a345-fafb-48f3-8d8b-c5f16b7b30a2&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.worksheetwiz.net&scope=openid&response_type=id_token&prompt=login";
  window.location.href = azureB2C_URL;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
