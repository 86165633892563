import { useState } from 'react';
import './App.css';
import { GetUserInfo, ResetCookies, handleSubscribeButtonClick } from './Utilities';
import { Button, Grid, Card, TextField, Typography, Backdrop, CircularProgress, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

function SolveArea(props) {

    const [fileUploadPreviewUrl, setFileUploadPreviewUrl] = useState("");

    const [backdropOpen, setBackdropOpen] = useState(false);

    const [worksheetType, setWorksheetType] = useState("questions")

    const [result, setResult] = useState("");

    const [showUpsellButton, setShowUpsellButton] = useState(false);

    const placeHolderImageUrl = "/Placeholder.jpg";

    //Don't use setFile to clear or change file input UI, it is one way
    const [file, setFile] = useState(null);

    const handleFileUploadChange = (event) => {
        console.log("1");
        if (event.target.files && event.target.files[0]) {
            console.log("2");
            setFile(event.target.files[0]);
            console.log("3");
            var reader = new FileReader();

            reader.onload = function (e) {
                setFileUploadPreviewUrl(e.target.result);
            };

            reader.readAsDataURL(event.target.files[0]);
        }
        else {
            setFile("");
        }
    }

    const handleSubmit = () => {
        // no file selected to read
        if (file == null) {
            alert("No file selected")
            return;
        }

        let apiUrl = "https://func-app-service-worksheetwiz.azurewebsites.net/api/AnswerFromImage?code=2sGO2u-rFhfHo5NuQT1b0dKW0Tp7Wq4VydRyeBFYcnq7AzFu3idTSQ==";

        let serverErrorText = "We were not able to process your worksheet as our services are currently under high demand. Please try again in a few minutes.";

        let insufficientWorksheetsRemainingText = "Insufficient worsheets remaining.";

        fetch(apiUrl + "&tenantId=" + GetUserInfo().tenantId + "&worksheetType=" + worksheetType, {
            method: "POST",
            body: file
        }).then(response => {
            switch(response.status)
            {
                //If unsubscribed user runs out, show upsell button
                case 402:
                    setShowUpsellButton(true);
                    handleResponse(insufficientWorksheetsRemainingText);
                    break;
                //If subscribed user runs out, don't show upsell button
                case 403:
                    handleResponse(insufficientWorksheetsRemainingText);
                    break;
                case 404:
                    ResetCookies();
                    window.location.reload();
                    break;
                case 200:
                    response.text().then(text => {
                        handleResponse(text);
                    });
                    break;
                default:
                    handleResponse(serverErrorText);
            }
        });

        setBackdropOpen(true);
    }

    const handleResponse = (text) => {
        setBackdropOpen(false);
        setResult(text);
    }

    const handleWorksheetTypeChange = (event) => {
        setWorksheetType(event.target.value);
    }

    const handleReset = () => {
        setResult("");
        setFile(null);
        setFileUploadPreviewUrl(placeHolderImageUrl);
        setShowUpsellButton(false);
        setWorksheetType("questions");
    }

    return (
        <div style={{width: '100%'}}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {result === "" ? (
                <Grid container spacing={2}>
                    <Grid className='app-row' item xs={12} style={{paddingTop: "50px"}}>
                        <Typography variant='h6' style={{ textAlign: "center" }}>Upload Photo of Worksheet</Typography>
                    </Grid>
                    <Grid className='app-row' item xs={12}>
                        <Card variant='elevation' className='upload-card'>
                            <Button className='app-button' style={{ marginRight: "15px" }} variant="contained" component="label" color='secondary'>
                                Upload File
                                <input
                                    type="file"
                                    onChange={handleFileUploadChange}
                                    accept="image/*"
                                    hidden
                                    value={""}
                                />
                            </Button>
                            <img alt="" src={fileUploadPreviewUrl === "" ? placeHolderImageUrl : fileUploadPreviewUrl} style={{ maxWidth: "180px", borderRadius: "5px" }} />
                        </Card>
                    </Grid>
                    <Grid className='app-row' item xs={12} style={{paddingTop: "35px", paddingBottom: "15px"}}>
                        <FormControl style={{color: "white"}}>
                            <InputLabel id="select-label">Worksheet Type</InputLabel>
                            <Select
                                labelId="select-label"
                                id="simple-select"
                                value={worksheetType}
                                label="Worksheet Type"
                                onChange={handleWorksheetTypeChange}
                                style={{color: "white", width: "200px"}}
                            >
                                <MenuItem value={"questions"}>Questions</MenuItem>
                                <MenuItem value={"chart"}>Chart</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid className='app-row' item xs={12}>
                        <Button className='app-button upload-button' variant='contained' color="primary" onClick={handleSubmit}>Solve</Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid container>
                    <Grid className='app-row' item xs={12}>
                        <Typography variant='h6' style={{ textAlign: "center" }}>Answers</Typography>
                    </Grid>
                    <Grid item xs={12} className="app-row">
                        <TextField className='result-text-field' multiline rows={20} value={result} />
                    </Grid>
                    <Grid item xs={12} className='app-row' style={{ "display": showUpsellButton ? "inline-flex" : "none", "paddingTop": "15px" }}>
                        <Button variant="contained" className="app-button success-button" onClick={() => {setBackdropOpen(true); handleSubscribeButtonClick(); }}>Subscribe for 20 worksheets per month</Button>
                    </Grid>
                    <Grid className='app-row' item xs={12}>
                        <Button className='app-button reset-button' variant='contained' color='secondary' onClick={handleReset}>Solve Another Worksheet</Button>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default SolveArea;
