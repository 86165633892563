import './App.css';
import SolveArea from './SolveArea';
import Layout from "./Layout";
import Account from "./Account";
import { Routes, Route } from "react-router-dom";

function App(props) {

  return (
    <>
        <Layout>
          <Routes>
            <Route path='account' element={<Account />} />
            <Route path='*' element={<SolveArea />} />
          </Routes>
        </Layout>
    </>
  );
}

export default App;
