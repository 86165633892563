import { loadStripe } from "@stripe/stripe-js";

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function ResetCookies()
{
    setCookie("email", "", -1);
    setCookie("tenantId", "", -1);
}


export function GetUserInfo()
{
    return {
        "email": getCookie("email"),
        "tenantId": getCookie("tenantId")
    }
}

export function dayIndexToName(index)
{
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    return days[index];
}

export function monthIndexToName(index)
{
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    return months[index]
}

export function handleSubscribeButtonClick()
{
    //Test Stripe Publishable Key
    //const stripeApiKey = "pk_test_3F2ZI8ZnwzEPVbxUQn6DDChm";

    //Live Stripe Publishable Key
    const stripeApiKey = "pk_live_kmwn6oD3q74jnISoTXE4AafQ";

    const checkoutApiUrl = "https://func-app-service-worksheetwiz.azurewebsites.net/api/CreateCheckoutSession?code=PGDTd9t8Se8rN2E9-vcDamMCXy1lXwzuLE_At_AUHxkUAzFuLXagKw==";

    fetch(checkoutApiUrl + "&tenantId=" + GetUserInfo().tenantId).then(response => response.text()).then(text => {
        loadStripe(stripeApiKey).then(stripe => {
            stripe.redirectToCheckout({
                sessionId: text
            })
        });
    })
}