import { Typography, Grid, Button, Divider, Backdrop, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { GetUserInfo, ResetCookies, dayIndexToName, monthIndexToName, handleSubscribeButtonClick } from "./Utilities";
import { useState, useEffect } from 'react';

function Account(props)
{

    const [backdropOpen, setBackdropOpen] = useState(true);

    const [worksheetsRemaining, setWorksheetsRemaining] = useState(0);

    const [subscriptionText, setSubscriptionText] = useState("");

    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    const [isTrial, setIsTrial] = useState(true);

    useEffect(() => {

        let getUserInfoApiUrl = "https://func-app-service-worksheetwiz.azurewebsites.net/api/GetAccount?code=hviwM1YC7t51nMrC5wCoSZJhkkZWNFsf3kvIDdBPWvuyAzFuswxoLA==";

        fetch(getUserInfoApiUrl + "&partitionKey=WIZ_ACCOUNT&id=" + GetUserInfo().tenantId).then(response => {
            if(response.status === 404)
            {
                ResetCookies();
                window.location.reload();
            }
            response.json().then(json => {
                setWorksheetsRemaining(json["worksheetsRemaining"]);
                setBackdropOpen(false);
                if(json["billingExpires"] === "trial")
                {
                    setSubscriptionText("Trial account");
                    setIsTrial(true);
                }
                else
                {
                    let expiryDate = new Date(json["billingExpires"]);

                    setSubscriptionText(`Worksheets will refill to 20 on ${dayIndexToName(expiryDate.getDay())}, ${monthIndexToName(expiryDate.getMonth())} ${expiryDate.getDate()}`);
                    setIsTrial(false);
                }
        })});
    }, [])

    const handleSubscriptionCancel = () => {
        const cancelSubscriptionApiUrl = "https://func-app-service-worksheetwiz.azurewebsites.net/api/CancelSubscription?code=eaVYBpN7rSGl_dlOJd0mizH0fuLFatY40TYY265mCX2zAzFu0cTMDg==";

        setBackdropOpen(true);
        setCancelDialogOpen(false);

        fetch(cancelSubscriptionApiUrl + "&tenantId=" + GetUserInfo().tenantId).then(response => { window.location.reload(); })
    }

    const handleCancelDialogClose = () => {
        setCancelDialogOpen(false);
    }

    return(
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={cancelDialogOpen}
                onClose={handleCancelDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cancel Subscription
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you would like to cancel your subscription? You will keep the worksheet submissions you have, but they will not be refilled.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDialogClose}>Back</Button>
                    <Button onClick={handleSubscriptionCancel} style={{color: "red"}}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2} style={{maxWidth: "700px", width: "90%"}}>
                <Grid item xs={12}>
                    <Typography variant="h2"><b>Account</b></Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">{GetUserInfo().email}</Typography>
                </Grid>
                <Grid item xs={12} style={{ "display": isTrial ? "block" : "none" }}>
                    <Button variant="contained" className="app-button success-button" onClick={() => {setBackdropOpen(true); handleSubscribeButtonClick(); }}>Subscribe for 20 worksheets per month</Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Remaining Worksheets: {worksheetsRemaining}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">{subscriptionText}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={12}>
                    <Button className="app-button danger-button" onClick={() => { ResetCookies(); window.location.reload(); }}>Sign Out</Button>
                </Grid>
                {isTrial === false && 
                <Grid item xs={12}>
                    <Button className="app-button danger-button" onClick={() => { setCancelDialogOpen(true) }}>Cancel Subscription</Button>
                </Grid>}
            </Grid>
        </>
    )
}

export default Account;