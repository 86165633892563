import { Fragment, useState } from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ResetCookies } from "./Utilities";

function Layout(props)
{
    

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSignOut = () => {
        ResetCookies();
        window.location.reload();
    }

    const handleGoToWiz = () => {
        navigate("/");
        handleClose();
    }

    console.log(props.children)
    return(
        <Fragment>
            <AppBar position='absolute' style={{ backgroundColor: "transparent"}} elevation={0}>
                <Toolbar>
                    <img alt="" onClick={handleGoToWiz} src="/Logo.png" style={{maxWidth: "200px", padding: "20px", cursor: "pointer"}} />
                    <Typography sx={{flexGrow: 1}}></Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleGoToWiz}>Wiz</MenuItem>
                            <MenuItem onClick={() => {navigate("/account"); handleClose(); }}>My account</MenuItem>
                            <MenuItem style={{color: "red"}} onClick={handleSignOut}>Sign Out</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <div className='app-container'>
                {props.children}
            </div>
        </Fragment>
    )
}

export default Layout;